<template>
<div class="main">
  <VxeOrgTree service="/sys/sysOrganization/treeQueryAuthCommon/communtiy" class="main_left" :expandAll="true" />
  <div class="main_right">
    <VxeBasicTable ref="xGrid" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions" :defaultTrigger="false">
    </VxeBasicTable>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      currNode: {},
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [{
              code: "create",
              name: "新增",
              status: "primary",
              events: {
                click: () => {
                  let { data } = this.formOptions
                  if (this.currNode.type == '1001') {
                    data["parentId"] = this.currNode.id;
                    data["organizationId"] = this.currNode.parentId;
                    data["communityId"] = this.currNode.communityId;
                    this.$refs.xGrid.insertEvent();
                  } else {
                    this.$message.error('请选择小区')
                  }
                }
              }
            },
            { code: "batchDelete", name: "批量删除", status: "danger" },
          ],
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60
          },
          {
            type: "checkbox",
            width: 60
          },
          {
            field: "name",
            title: "项目名称",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入项目名称"
              }
            }
          },
          {
            field: "position",
            title: "广告位置",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入广告位置"
              }
            }
          },
          {
            field: "amount",
            title: "广告金额",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 99999,

                placeholder: "请输入广告金额"
              }
            }
          },
          {
            field: "startDate",
            title: "开始日期",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                type: "date",
                valueFormat: "yyyy-MM-dd HH:mm:ss",
                placeholder: "请选择开始日期"
              }
            }
          },
          {
            field: "endDate",
            title: "结束日期",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                type: "date",
                valueFormat: "yyyy-MM-dd HH:mm:ss",
                placeholder: "请选择结束日期"
              }
            }
          },
          {
            field: "enabled",
            title: "状态",
            width: 120,
            cellRender: {
              name: "$switch",
              optionTypeCode: "enabledStatus",
              props: {
                openLabel: "启用",
                closeLabel: "禁用",
                openValue: "1",
                closeValue: "0"
              },
              events: {
                change: ({ row }) => {
                  this.$refs.xGrid.submitEvent(row)
                }
              }
            },
          },
          {
            field: "updateTime",
            title: "更新日期",
            minWidth: 160
          },
          {
            fixed: "right",
            title: "操作",
            width: 160,
            editRender: {
              name: "$VxeGridButtons",
              lists: [{
                  status: "primary",
                  label: "修改",
                  name:'edit',
                  eventName: "queryEvent"
                },
                {
                  status: "danger",
                  label: "删除",
                  name:'delete',
                  eventName: "removeEvent"
                }
              ]
            }
          }
        ],
        editConfig: {},
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 100,
        items: [{
            field: "parentId",
            title: "所属上级",
            span: 24,
            itemRender: {
              name: "$VxeSelectTree",
              service: "/sys/sysOrganization/treeQueryAuthCommon/communtiy",
              defaultProps: {
                label: "name",
                value: "id",
              },
              expandAll: true,
              props: {
                placeholder: "请选择所属上级",
              },
              events: {
                click: (params, node) => {
                  let { data } = params;
                  if (node.type == '1001') {
                    data["parentId"] = node.id;
                    data["communityId"] = node.communityId;
                    data["organizationId"] = node.parentId;
                  } else {
                    this.$message.error('请选择小区')
                  }
                }
              }
            },
          },
          {
            field: "name",
            title: "项目名称",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入项目名称"
              }
            }
          },
          {
            field: "amount",
            title: "广告金额",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 99999,

                maxlength: 24,
                placeholder: "请输入广告金额"
              }
            }
          },
          {
            field: "position",
            title: "广告位置",
            span: 24,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入广告位置"
              }
            }
          },
          {
            field: "startDate",
            title: "开始日期",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "date",
                valueFormat: "yyyy-MM-dd HH:mm:ss",
                placeholder: "请选择开始日期"
              }
            }
          },
          {
            field: "endDate",
            title: "结束日期",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "date",
                valueFormat: "yyyy-MM-dd HH:mm:ss",
                placeholder: "请选择结束日期"
              }
            }
          },
          {
            field: "enabled",
            title: "状态",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "enabledStatus",
              props: {
                placeholder: "请选择状态"
              }
            }
          },
          {
            field: "remark",
            title: "描述",
            span: 24,
            itemRender: {
              name: "$textarea",
              props: {
                showWordCount: true,
                maxlength: "300",
                resize: "none",
                placeholder: "请输入描述内容"
              }
            }
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary"
                  }
                },
                {
                  props: {
                    type: "reset",
                    content: "取消"
                  }
                }
              ]
            }
          }
        ],
        //表单校验规则
        rules: {
          name: [{
            required: true,
            message: "请输入项目名称"
          }],
          position: [{
            required: true,
            message: "请输入广告位置"
          }],
          startDate: [{
            required: true,
            message: "请选择开始日期"
          }],
          endDate: [{
            required: true,
            message: "请选择结束日期"
          }],
          amount: [{
            required: true,
            message: "请输入广告金额"
          }],
          enabled: [{
            required: true,
            message: "请选择状态"
          }]
        },
        // 新增功能字段初始化
        data: {
          enabled: "1",
          name: "",
          position: "",
          amount: "",
          startDate: "",
          endDate: ""
        },
      },

      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "name",
            title: "项目名称",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入项目名称"
              }
            }
          },
          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary"
                  }
                },
                {
                  props: {
                    type: "reset",
                    content: "重置"
                  }
                }
              ]
            }
          }
        ],
        data: {
          name: "",
        },
      },
    };
  },

  methods: {
    handleNodeClick(treeData, node) {
      this.currNode = node;
      this.$Tools.buildDataToGrid(this.gridOptions.columns, treeData, "parentId");
      this.$Tools.buildDataToGrid(this.formOptions.items, treeData, "parentId");
      this.searchOptions.data['parentId'] = node.id;
      this.$refs.xGrid.getLists();
    },
  },
};
</script>

<style lang="scss" scoped></style>
